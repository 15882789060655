<template>
  <div id="logistics">
    <img src="@/assets/images/InsuranceServices-bg.png" alt="" />
    <div class="recommended">
      <div class="title">
        <img src="@/assets/images/slash.png" alt="" />
        <p>优质保险服务商推荐</p>
        <img src="@/assets/images/slash.png" alt="" />
      </div>
      <div class="content">
        <div v-for="(list, i) in courier" :key="i">
          <a :href="list.a" target="blank"></a>
          <img :src="list.img" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "insurance-services",
  data() {
    return {
      courier: [
        {
          img: require("@/assets/images/bx-logo.png"),
          a: "http://www.guorenpcic.com/web/bdyz/index.jhtml",
        },
        {
          img: require("@/assets/images/InsuranceServices-2.png"),
          a: "https://baoxian.pingan.com/pa18shopnst/nstShop/index.html#/yanxuan?WT.mc_id=T00-BD-155-1-001-003633&WT.srch=1",
        },
        {
          img: require("@/assets/images/InsuranceServices-3.png"),
          a: "https://www.chinalife.com.cn/chinalife/index/",
        },
        {
          img: require("@/assets/images/InsuranceServices-4.png"),
          a: "http://www.cpic.com.cn/",
        },
        {
          img: require("@/assets/images/InsuranceServices-5.png"),
          a: "http://www.cic.cn/",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#logistics {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  > img {
    width: 100%;
  }
  .recommended {
    width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      display: flex;
      align-items: center;
      margin: 30px 0 40px 0;
      p {
        font-size: 24px;
        line-height: 31px;
        color: #333333;
        margin: 0 10px;
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 60px;
      div {
        width: 220px;
        height: 124px;
        background: #ffffff;
        border: 1px solid #dddddd;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
        a {
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }
    }
  }
}
</style>